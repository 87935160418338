/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState, Fragment } from 'react';
import style from './style';
import utils from '../../utils';
import cmsUtils from '../../cms/utils/cmsUtils';
//import { useGet, Loading, ErrorPopup } from '../../components';
import env from '../../env';
import parse from 'html-react-parser';
//import { createPortal } from "react-dom";
//import { FaSpinner } from "react-icons/fa";

export function ClubTimer(props){

  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'club_timer', item.cssClass || item.anchorName || '');
  const clubId = cmsUtils.payload(item, "SelectedCategories");
  const [checkExpire, setCheckExpire] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState();


  
  useEffect(() => {
    if (cmsOption.isCmsEdit){
      return;
    }

    const fetchData = async () => {
      const res = await fetch(env.apiBase + '/api/club/getclub/' + clubId);
      const data = await res.json();
      
      if(data.results.club && (data.results.club.deliveryDate && data.results.club.deliveryDate !== "")){
        const _updatedText = data.results.club.deliveryDate.replace('th', '<sup>th</sup>');
        //setDeliveryDate(data.results.club.deliveryDate);
        setDeliveryDate(_updatedText);
      }
      
      if(data.results.club && (data.results.club.expireAt && data.results.club.expireAt !== "")){
        
        let _convertedDateTime = data.results.club.expireAt.replace(/ /g, "T");
        var expireAt =  new Date(_convertedDateTime);
        var now = new Date();
        
        if(expireAt.getTime() > now.getTime()){
          var twoDaysFromNow = (expireAt.getTime() / 1000) + 1;
          var flipdown = new window['FlipDown'](twoDaysFromNow).start()
          .ifEnded(() => {
            console.log('The countdown has ended!');
            
          });
          console.log(flipdown);
          
        }
        else{
          setCheckExpire(true);
          console.log("111");
          
        }
      }
      else{
        
        setCheckExpire(true);
        console.log("222");
        
      }   
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  if (cmsOption.isCmsEdit){
    return(
      <div className={cssClass} data-cms-item-id={item.itemId} id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b> Club Timer </b>
        </pre>
      </div>
    )
  }

  return(
    <div css={style.club__timer__container} className={cssClass} id={item.anchorName} style={{backgroundColor:'white'}}>
      {
        checkExpire ? <div css={style.order__close__text}> Order Closed </div> :
        <Fragment>
          {
            deliveryDate &&
            // <div css={style.order__close__text}> {"Delivery Date From : " + deliveryDate} </div>
            <div css={style.order__close__text}>
              Delivery Date From : <span>{parse(deliveryDate)}</span>
            </div>
          }
          <div id="flipdown" className="flipdown"></div>
        </Fragment> 
      }
      
    </div>
  )

}
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, Fragment } from 'react';
import env from '../../../env';
import { SiteLink } from '../../../components'; //useRouter
import { useForm } from '../../../form';
import { fb, validators } from '../../../form';
import { Button, ErrorMessage } from '../../../components';
import { usePost } from '../../../components';
import utils from '../../../utils';
import style from './productViewStyle';
import { useStore } from '../../../store';
import { ProductPrice } from './price';

export function AddToCart({ product, variantOnly, openPopupImage }) {

  const post = usePost();
  //const { Redirect } = useRouter();
  //const history = useHistory();
  const render = (name, parent = null) => form.renderControl(name, parent);
  const model = getFormModel(product, {});
  const form = useForm(model, { usePlaceholder: true, onControlChanged: onControlChanged });

  const [attributesEx, setAttributesEx] = useState(() => {
    let _array = [];
    for (let n in product.variantAttributes) {
      console.log(n, product.variantAttributes[n].options[0].value);
      _array.push(product.variantAttributes[n].options[0].value);
    }
    return _array;
  });

  console.log(attributesEx);

  const [checkGoToCartBtn, setCheckGoToCartBtn] = useState(false);
  const { dispatch, state } = useStore();

  function onControlChanged(e, control) {
    if (!(control.type === 'select' && control.parent)) {
      return;
    }
    const newModel = getFormModel(product, form.getValue())
    form.reset(newModel)
  }

  function addToCart(e) {
    form.validateForm(e, () => {
      const formVal = form.getValue();
      let _variantOptions = [];
      for (let i in formVal.variantOptions) {
        let _item = {
          name: formVal.variantOptions[i].name,
          value: formVal.variantOptions[i][Object.keys(formVal.variantOptions[i])[1]]
        };
        _variantOptions.push(_item);
      }
      //const value = { ...formVal, productId: product.productId, cartId: env.getDevCartId() };
      let _cartId = env.getDevCartId();
      const value = {
        quantity: formVal.quantity,
        specialRequest: formVal.specialRequest,
        variantOptions: _variantOptions,
        productId: product.productId,
        cartId: _cartId === 'null' ? null : _cartId
      };

      setCheckGoToCartBtn(false);
      post.send(env.apiBase + "/api/cart/addtocart", value);

    })
  }

  if (post.done()) {
    env.setDevCartId(post.response.results.cartInfo.cartId);
    if (post.response.errors.length < 1) {
      setTimeout(() => {
        dispatch({ type: 'SET_STATE', payload: { cartInfo: post.response.results.cartInfo.totalQuantity === 0 ? 0 : (post.response.results.cartInfo.totalQuantity) - 1 } });
        setCheckGoToCartBtn(true);
      }, 100);
    }
    post.reset();
    // return <Redirect to="/cart" />
  }

  if (post.hasErrors()) {
    if (post.errors[0].includes("There are non-compatible products")) {
      setTimeout(() => {
        setCheckGoToCartBtn(true);
      }, 500);
    }
  }

  const attributes = form.findControl('variantOptions');
  return <div style={{ marginBottom: '1.5rem' }}>

    {
      product.productStatus !== 100 &&

      <Fragment>
        {attributes.controls.length > 0 && <hr />}

        {
          attributes.controls.map(
            (item, index) => {
              return (
                <div className="row" key={index} css={style.newOptionRow}>

                  <div className="col-12" style={{ fontWeight: 600 }}>
                    {item.controls[0].value}
                  </div>

                  <div className="col-12">
                    <div css={style.newOptionStyleContainer}>
                      {
                        item.controls[1].options.map((sitem, sindex) =>
                          <div key={sindex} css={style.newOptionStyleItem} className={`${item.controls[1].value === sitem.value ? "selected" : ""}`} onClick={() => {
                            item.controls[1].value = sitem.value;
                            setAttributesEx(pre => {
                              let _new = { ...pre };
                              return _new;
                            });
                          }}>
                            {sitem.name}
                          </div>)
                      }
                    </div>
                  </div>
                </div>
              )
            }
          )
        }

      </Fragment>

    }

    <div className="form-row">
      <div className="qty col-md-12">
        {render('specialRequest')}
      </div>
    </div>

    {
      (product.chartImage && product.chartImage !== "") &&
      <div css={style.sizeChartBtn} onClick={() => {
        openPopupImage();
      }}>
        View Size Chart
      </div>
    }

    {!variantOnly && <form onSubmit={addToCart} style={{ textAlign: 'left', marginBottom: '2rem' }}>

      <ProductPrice product={product} currency={state.currency} />

      <div className="form-row">
        <div className="qty col-md-4">
          {render('quantity')}
        </div>
        <div className="col-md-8" css={style.addToCartBtn}>
          <Button onClick={addToCart} status={form.status}> ADD TO CART </Button>
        </div>
        <div className="col-md-12">
          <ErrorMessage errors={post.errors} />
        </div>
        <div className="col-md-8 offset-md-4">
          {
            checkGoToCartBtn === true &&
            <SiteLink className="btn-primary btn" to="/cart" style={{ marginTop: '0.5rem' }}> GO TO CART </SiteLink>
          }
        </div>
      </div>

      <div>
        {/* <Button onClick={addToCart} status={form.status}>Add to cart</Button> */}
        <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
      </div>
    </form>}
  </div>
}

function getFormModel(product, formValues /* for reset */) {
  const variantAttributes = product.variantAttributes;

  const model = fb.group({
    //quantity: [formValues.quantity || 1, [validators.Required()], { label: 'Quantity', type: 'number' }],
    quantity: [formValues.quantity || 1, [validators.Required()], { label: 'Quantity', type: 'number' }],
    specialRequest: ['', [], { label: 'Special Request', type: 'textarea', attrs: { rows: '1' } }],
    variantOptions: fb.array([]),
  });
  const variantOptionArray = model.find('variantOptions').controls;
  (variantAttributes || []).forEach(attr => {
    variantOptionArray.push(newVariantAttribute(attr, formValues, product.variants))
  })
  return model;
}

// function newVariantAttribute(attr, formValues, variants) {
//   //console.log(attr, formValues, variants)
//   const optionsSelected = formValues.variantOptions || [];
//   const currentSelection = utils.array.find(optionsSelected, x => attr.name === x.name);
//   const filtered = variants
//     .filter(x => matchVariantOptions(optionsSelected, x.variantOptions, attr.name))
//   const availableOptions = getAvailableOptionValues(filtered, attr.name);
//   const options = attr.options.map(x => { return { ...x, ...{ disabled: !availableOptions.some(o => o === x.value) } } })
//   return fb.group({
//     name: [attr.name, [], { type: 'hidden' }],
//     value: [(currentSelection && currentSelection.value) || attr.value || '',
//     [validators.Required()], {
//       //label: attr.name,
//       label: null,
//       type: 'select',
//       options: [{ displayName: 'Select ...', value: '' }, ...options].map(x => {
//         return {
//           name: (x.displayName || x.value) + (x.disabled || false ? ' - not available' : ''),
//           value: x.value,
//           disabled: x.disabled || false
//         }
//       })
//     }]
//   })
// }

function newVariantAttribute(attr, formValues, variants) {

  // const choices = [
  //   { name: 'Yes, please!', value: 'Yes' },
  //   { name: 'No, thanks', value: 'No' }
  // ]

  const dyName = attr.name.toLowerCase();
  const optionsSelected = formValues.variantOptions || [];
  const currentSelection = utils.array.find(optionsSelected, x => attr.name === x.name);

  const filtered = variants
    .filter(x => matchVariantOptions(optionsSelected, x.variantOptions, attr.name))
  const availableOptions = getAvailableOptionValues(filtered, attr.name);
  const options = attr.options.map(x => { return { ...x, ...{ disabled: !availableOptions.some(o => o === x.value) } } });

  return fb.group({
    name: [attr.name, [], { type: 'hidden' }],
    [dyName]: [(currentSelection && currentSelection.value) || attr.value || options[0].value,
    [validators.Required()], {
      //label: attr.name,
      label: null,
      type: 'radioList',
      //options : choices
      options: [...options].map(x => {
        return {
          name: (x.displayName || x.value) + (x.disabled || false ? ' - not available' : ''),
          value: x.value,
          disabled: x.disabled || false
        }
      })
    }]
  })
}

function getAvailableOptionValues(variants, name) {
  const values = variants.map(x => {
    const options = x.variantOptions.filter(o => o.name === name)
    return options && options[0].value
  }).filter(x => x);

  return Array.from(new Set(values));
}

function matchVariantOptions(optionsSelected, optionsInVariant, currentControlName) {
  //console.log(optionsSelected, optionsInVariant, currentControlName)
  for (let i = 0; i < optionsSelected.length; i++) {
    let optionSelected = optionsSelected[i];
    if (optionSelected.value === '') {
      // skip match test
      continue;
    }
    if (optionSelected.name === currentControlName) {
      // skip match test for the just selected value
      continue;
    }
    let found = false;
    for (let j = 0; j < optionsInVariant.length; j++) {
      let optionInVariant = optionsInVariant[j];
      if (optionSelected.name === optionInVariant.name && optionSelected.value === optionInVariant.value) {
        found = true;
        break;
      }
    }
    if (!found) return false;
  }
  return true;
}
const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  blueColor : '#00aeef',
  greyColor : '#505050'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.blueColor,
  fontColor: 'black',
  bgColor: '#ebebeb',

  ...colors,
  ...overloading
}

export default variables;
/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './orderDetailsStyle';
import React, { Fragment } from 'react';

import env from '../../../env';
import { useGet } from '../../../components';
import { Loading, ErrorPopup } from '../../../components';
import { useRouter } from '../../../components';
import utils from '../../../utils';

import { gtm } from '../../../lib/tracking';
//import { sendPurchaseEvent } from '../gtm';

export function OrderDetails() {
  const get = useGet();
  const { pathname, query } = useRouter();
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const o = query.o || '';

  React.useEffect(() => {
    get.send(env.apiBase + '/api/order/getPaidOrder/' + o);
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }
  const order = get.response;
  
  if (!pageLoaded && order) {
    setPageLoaded(true);
    if (!query.noGTM) gtm.pageView(pathname, 'Order Thankyou');
    // if (!query.noGTM) sendPurchaseEvent(order);
    return null;
  }

  return <Details order={order} />;
}

function Details({ order }) {
  const orderItems = order.orderItems.filter((x) => x.orderItemType === 0);
  let currency = "AU";
  if(order.orderExtInfo && (order.orderExtInfo.currency && order.orderExtInfo.currency !== '')){
    if(order.orderExtInfo.currency === "NZD"){
      currency = "NZ";
    }
  }

  //const taxLines = order.orderItems.filter((x) => x.orderItemType === 1);

  function itemAmount(orderItem) {
    return (
      (orderItem.unitPrice * 100 +
        (orderItem.addedUnitPrice ? orderItem.addedUnitPrice * 100 : 0) -
        (orderItem.discountUnitPrice ? orderItem.discountUnitPrice * 100 : 0)) /
      100
    );
  }
  // function itemTotalAmount(orderItem) {
  //   return (orderItem.quantity * (orderItem.unitPrice * 100 + orderItem.addedUnitPrice * 100 - orderItem.discountUnitPrice * 100)) / 100;
  // }

  function getPromoCodeText() {
    const _promoCode = order.promoCode.split('|');
    return _promoCode[0] + ' : ' + _promoCode[1] + '% Discount';
  }

  return (
    <div css={style.orderDetails}>
      <hr />
      <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
        <div className='col-md-12'>
          <div>
            <label css={style.fn}>Order Number:</label> <span css={style.fv}>{order.orderNo}</span>{' '}
          </div>
          <div>
            <label css={style.fn}>Total Amount:</label>{' '}
            <span css={style.fv}>
              <b> {currency} {utils.money.formatMoney(order.totalAmount)}</b>
            </span>{' '}
          </div>
          <div>
            <label css={style.fn}>Order Date:</label> <span css={style.fv}>{utils.date.formatYYYYMMDD(order.orderDate)}</span>{' '}
          </div>
        </div>
      </div>
      <hr />

      <h3>ITEMS TEST</h3>
      <div css={style.orderItems}>
        {orderItems.map((orderItem, i) => (
          <div css={style.orderItem} className='row' key={orderItem.orderItemId}>
            <div className='col-6'>
              <b dangerouslySetInnerHTML={{ __html: orderItem.itemName }}></b>
              <div dangerouslySetInnerHTML={{ __html: orderItem.itemDescHtml }}></div>
              {(orderItem.itemName === 'Kit Build' || orderItem.itemName === 'Kit Builder') && <KitBuildDetail kitBuild={orderItem.extSerialized} />}
            </div>
            <div className='col-3'>
              <div className='price'>Unit Price: {currency} {utils.money.formatMoney(itemAmount(orderItem))}</div>
            </div>
            <div className='col-3'>Qty: {orderItem.quantity}</div>
          </div>
        ))}

        <div css={style.orderItem} className='row'>
          <div className='col-9'>Shipping Fee:
              {
                (order.orderExtInfo && order.orderExtInfo.shippingText && order.orderExtInfo.shippingText !== '') &&
                <span css={style.taxText}> { '(' + order.orderExtInfo.shippingText + ')'} </span>
              }
          </div>
          <div className='col-3'>
            <div css={style.amount}>
              <b> {currency} {utils.money.formatMoney(order.shippingAmount)}</b>
            </div>
          </div>
        </div>

        <div css={style.orderItem} className='row'>
            <div className='col-9'>
              Tax: GST

              {
                (order.orderExtInfo && order.orderExtInfo.taxRate && order.orderExtInfo.taxRate !== '') &&
                <span css={style.taxText}> { '(' + order.orderExtInfo.taxRate + ')'} </span>
              }

            </div>
            
            <div className='col-3'>
              <div css={style.amount}>
                <b> {currency} {utils.money.formatMoney(order.taxAmount)}</b>
              </div> 
            </div>

          </div>

        {order.promoCode && order.promoCode !== '' && (
          <div className='row' css={style.orderItem}>
            <div className='col-9'>Promo Code : {getPromoCodeText()}</div>
            <div className='col-3'>
              <div css={style.amount}>
                <b> {'- ' + utils.money.formatMoney(order.discountAmount)}</b>
              </div>
            </div>
          </div>
        )}

        <div className='row' style={{ marginLeft: '0px', marginRight: '0px' }}>
          <div className='col-12'>
            <div css={style.total}>
              Total: {currency} {utils.money.formatMoney(order.totalAmount)}
              {/* <span className="total-price">Item Total: {utils.money.formatMoney(order.itemAmount)}
                <span> + Shipping Fee: {order.shippingAmount > 0 ? 'AU ' + utils.money.formatMoney(order.shippingAmount) : 'FREE'}</span>
                <span> + GST: {utils.money.formatMoney(order.taxAmount)}</span>
              </span>
              <div className="grand-total">Grand Total (including GST): AU {utils.money.formatMoney(order.totalAmount)}
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <hr />
      {/* <h3>CONTACT</h3> */}
      <h3>DELIVERY METHOD</h3>
      <div>{order.shippingMethod}</div>
      {order.shippingAddress && order.shippingMethod === 'Standard' && (
        // <Address addr={order.billingAddress} />
        <Address addr={order.shippingAddress} />
      )}
      <Billing billingAddr={order.billingAddress} useSameAddress={order.useSameAddress} />
    </div>
  );
}

function Address({ addr }) {

  return (
    <div>
      <hr />
      <h3> SHIPPING ADDRESS </h3>
      <div>
        <label css={style.fn}>First Name:</label> <span css={style.fv}>{addr.firstName}</span>{' '}
      </div>
      {/* <div>
        <label css={style.fn}>Surname:</label> <span css={style.fv}>{addr.surname}</span>{' '}
      </div> */}
      <div>
        <label css={style.fn}>Country:</label> <span css={style.fv}>{addr.country}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>Address:</label>
        <span css={style.fv} style={{ marginLeft: '6px' }}>
          {addr.street1}
        </span>
        <span css={style.fv} style={{ marginLeft: '0.7rem' }}>
          {addr.street2}
        </span>
      </div>
      <div>
        <label css={style.fn}>Suburb:</label> <span css={style.fv}>{addr.suburb}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>State:</label> <span css={style.fv}>{addr.state}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>Postcode:</label> <span css={style.fv}>{addr.postcode}</span>{' '}
      </div>
      <div>
        <label css={style.fn}>Email Address:</label> <span css={style.fv}>{addr.email}</span>{' '}
      </div>
      {/* <div>
        <label css={style.fn}>Mobile:</label> <span css={style.fv}>{addr.mobile}</span>{' '}
      </div> */}
    </div>
  );
}

function Billing(props) {
  const billingAddr = props.billingAddr;
  const useSameAddress = props.useSameAddress;
  return (
    <div>
      <hr />
      <h3> BILLING ADDRESS </h3>
      {useSameAddress === true && <div> Use the same address </div>}
      {billingAddr && useSameAddress === false && (
        <Fragment>
          <div>
            <label css={style.fn}>First Name:</label> <span css={style.fv}>{billingAddr.firstName}</span>{' '}
          </div>
          {/* <div>
            <label css={style.fn}>Surame:</label> <span css={style.fv}>{billingAddr.surname}</span>{' '}
          </div> */}
          <div>
            <label css={style.fn}>Country:</label> <span css={style.fv}>{billingAddr.country}</span>{' '}
          </div>
          <div>
            <label css={style.fn}>Address:</label>
            <span css={style.fv}> {billingAddr.street1} </span>
            <span css={style.fv} style={{ marginLeft: '0.5rem' }}>
              {' '}
              {billingAddr.street2}{' '}
            </span>
          </div>
          <div>
            <label css={style.fn}>Suburb:</label> <span css={style.fv}>{billingAddr.suburb}</span>{' '}
          </div>
          <div>
            <label css={style.fn}>State:</label> <span css={style.fv}>{billingAddr.state}</span>{' '}
          </div>
          <div>
            <label css={style.fn}>Postcode:</label> <span css={style.fv}>{billingAddr.postcode}</span>{' '}
          </div>
          <div>
            <label css={style.fn}>Email Address:</label> <span css={style.fv}>{billingAddr.email}</span>{' '}
          </div>
          {/* <div>
            <label css={style.fn}>Mobile:</label> <span css={style.fv}>{billingAddr.mobile}</span>{' '}
          </div> */}
        </Fragment>
      )}
    </div>
  );
}

function KitBuildDetail(props) {
  const kitBuildModel = props.kitBuild ? JSON.parse(props.kitBuild) : {};
  //console.log("kitBuildModel", kitBuildModel);
  return <div>{kitBuildModel.SingleItemCheckout.Name}</div>;
}

// eslint-disable-next-line
{
  /* <h4 style="margin-top:1.5em;">Delivery Method</h4>
<div>
  <div className="col-12">
    <div *ngIf="order.shippingMethod == 'Pickup'">
      <p style="margin: 1rem;"><b>Pickup</b></p>
      <div *ngIf="order.pickupLocation">
        <h5>Selected Location</h5>
        <div className="selected-pickup-location">
          <div><b>{{order.pickupLocation.companyName}}</b></div>
          <div>{{order.pickupLocation.suburb}}, {{order.pickupLocation.state}}</div>
          <div>T: {{order.pickupLocation.phone}}</div>
        </div>
      </div>
    </div>
    <div *ngIf="order.shippingMethod != 'Pickup'">
      {{order.shippingMethod}}
    </div>

  </div>
</div>

<h4 style="margin-top:1.5em;">Shipping/Billing Information</h4>
<div className="addresses">

  <div className="row">
    <div className="col-6" *ngIf="order.shippingMethod != 'Pickup'">
      <h5>Shipping
        <a *ngIf="gmapShipping" [href]="gmapShipping" target="_blank"><i className="material-icons">place</i></a>
      </h5>
      <div className="fld"><label>First Name:</label> <span>{{order.shippingAddress.firstName}}</span> </div>
      <div className="fld"><label>Surname:</label> <span>{{order.shippingAddress.surname}}</span> </div>
      <div className="fld"><label>Email:</label> <span>{{order.shippingAddress.email}}</span> </div>
      <div className="fld"><label>Phone:</label> <span>{{order.shippingAddress.phone}}</span> </div>
      <div className="fld"><label>Address:</label> <span>{{order.shippingAddress.addressLine}}</span> </div>
      <div className="fld"><label>Suburb:</label> <span>{{order.shippingAddress.suburb}}</span> </div>
      <div className="fld"><label>State:</label> <span>{{order.shippingAddress.state}}</span> </div>
      <div className="fld"><label>Postcode:</label> <span>{{order.shippingAddress.postcode}}</span> </div>
    </div>
    <div className="col-6">
      <h5>Billing
        <a *ngIf="gmapBilling" [href]="gmapBilling" target="_blank"><i className="material-icons">place</i></a>
      </h5>
      <div *ngIf="order.useSameAddress">Use the same address</div>
      <div *ngIf="!order.useSameAddress">
        <div className="fld"><label>First Name:</label> <span>{{order.billingAddress.firstName}}</span> </div>
        <div className="fld"><label>Surname:</label> <span>{{order.billingAddress.surname}}</span> </div>
        <div className="fld"><label>Email:</label> <span>{{order.billingAddress.email}}</span> </div>
        <div className="fld"><label>Phone:</label> <span>{{order.billingAddress.phone}}</span> </div>
        <div className="fld"><label>Address:</label> <span>{{order.billingAddress.addressLine}}</span> </div>
        <div className="fld"><label>Suburb:</label> <span>{{order.billingAddress.suburb}}</span> </div>
        <div className="fld"><label>State:</label> <span>{{order.billingAddress.state}}</span> </div>
        <div className="fld"><label>Postcode:</label> <span>{{order.billingAddress.postcode}}</span> </div>

      </div>
    </div>
  </div>
</div>
<hr />
<h4 style="margin-top:1.5em;">Special Request</h4>
<div className="row">
  <div className="col-12">
    <div style="white-space:pre-line">
      {{order.specialRequest? order.specialRequest : "N/A"}}
    </div>
  </div>
</div> */
}
